import React from 'react';
import Section from 'components/common/Section';
import { Helmet } from 'react-helmet-async';

const Datenschutz = () => {
  return (
    <>
      <Helmet>
        <title>ScanBox product</title>

        <meta
          name="description"
          content="Ein benutzerfreundliches Tool unterstützt IT-Administratoren bei der effizienten Sicherheitsanalyse"
        />
      </Helmet>
      <Section className="bg-body-tertiary px-2 pt-5">
        <div className=" px-3">
          <h2 className="text-header fw-bold pb-4 mb-2">
            Datenschutzerklärung
          </h2>
          <div>
            <h3 className=" text-header pb-1 pt-1 ">
              1. Datenschutz auf einen Blick
            </h3>
            <h4 className="text-600 pb-2">Allgemeine Hinweise</h4>
            <p className="text-600 text-justify pb-2">
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>

            <h4 className="text-600 pb-2">Datenerfassung auf dieser Website</h4>
            <h4 className="text-600 pb-2">
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </h4>
            <p className="text-600 text-justify pb-2">
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
              dieser Website entnehmen.
            </p>

            <h4 className="text-600 pb-2">Wie erfassen wir Ihre Daten?</h4>
            <p className="text-600 text-justify pb-2">
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben. Andere Daten werden automatisch oder
              nach Ihrer Einwilligung beim Besuch der Website durch unsere
              IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
              Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).
              Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
              Website betreten.
            </p>

            <h4 className="text-600 pb-2">Wofür nutzen wir Ihre Daten?</h4>
            <p className="text-600 text-justify pb-2">
              Die Daten werden erhoben, um eine fehlerfreie Bereitstellung der
              Website zu gewährleisten.
            </p>
          </div>

          <div>
            <h3 className=" text-header pb-1 pt-1 ">
              2. Allgemeine Hinweise und Pflichtinformationen
            </h3>
            <h4 className="text-600 pb-2">Datenschutz</h4>
            <p className="text-600 text-justify pb-2">
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn
              Sie diese Website benutzen, werden verschiedene personenbezogene
              Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
              persönlich identifiziert werden können. Die vorliegende
              Datenschutzerklärung erläutert, welche Daten wir erheben und wofür
              wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
              geschieht.
            </p>

            <p className="text-600 text-justify pb-2">
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
              B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>

            <h4 className="text-600 pb-2">
              Hinweis zur verantwortlichen Stelle
            </h4>
            <p className="text-600 text-justify pb-2">
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </p>
            <p className="text-600 text-justify pb-2 d-flex ">
              <div className="me-5 pe-5">
                DECOIT® GmbH & Co. KG
                <br />
                Fahrenheitstraße 9<br />
                28359 Bremen
                <br />
              </div>
              <div className="ms-2 ps-2">
                <strong>Phone:</strong> +49 (0) 421 - 59 60 64-0
                <br />
                <strong>Fax:</strong> +49 (0) 421 - 59 60 64-09
                <br />
                <strong>E-Mail:</strong>{' '}
                <a href="mailto:datenschutz@decoit.de">datenschutz@decoit.de</a>
              </div>
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">Speicherdauer</h4>
            <p className="text-600 text-justify pb-2">
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere
              Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
              Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
              Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
              Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
              gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe
              für die Speicherung Ihrer personenbezogenen Daten haben (z.B.
              steuer- oder handelsrechtliche Aufbewahrungsfristen); im
              letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
              Gründe.
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">
              Gesetzlich vorgeschriebener Datenschutzbeauftragter
            </h4>
            <p className="text-600 text-justify pb-2">
              Wir haben für unser Unternehmen einen Datenschutzbeauftragten
              bestellt.
            </p>
            <p className="text-600 text-justify pb-2 d-flex ">
              <div className="me-5 pe-5">
                MERENTIS DataSec GmbH
                <br />
                Kurfürstenallee 130
                <br />
                28211 Bremen
                <br />
              </div>
              <div className="ms-2 ps-2">
                <strong>Phone:</strong> +49 (0) 421 - 23804-0
                <br />
                <strong>Fax:</strong> +49 (0) 421 - 23804-10
                <br />
                <strong>E-Mail:</strong>{' '}
                <a href="mailto:datasec@merentis.com">datasec@merentis.com</a>
              </div>
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </h4>
            <p className="text-600 text-justify pb-2">
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>

            <h4 className="text-600 pb-2">
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO)
            </h4>
            <p className="text-600 text-justify pb-2">
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
              DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
              VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
              DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
              WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
              PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
              KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">
              Beschwerderecht bei der zuständigen Aufsichtsbehörde
            </h4>
            <p className="text-600 text-justify pb-2">
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>

            <h4 className="text-600 pb-2">Recht auf Datenübertragbarkeit</h4>
            <p className="text-600 text-justify pb-2">
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">SSL- bzw. TLS-Verschlüsselung</h4>
            <p className="text-600 text-justify pb-2">
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS- Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile.
            </p>

            <p className="text-600 text-justify pb-2">
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </p>

            <h4 className="text-600 pb-2">
              Auskunft, Löschung und Berichtigung
            </h4>
            <p className="text-600 text-justify pb-2">
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden.
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">
              Recht auf Einschränkung der Verarbeitung
            </h4>
            <p className="text-600 text-justify pb-2">
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
              folgenden Fällen:
            </p>

            <ul className="text-600 text-justify pb-2">
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah/geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </li>
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
            </ul>

            <p className="text-600 text-justify pb-2">
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
          </div>

          <div>
            <h3 className=" text-header pb-1 pt-1 ">
              3. Datenerfassung auf dieser Website
            </h3>
            <h4 className="text-600 pb-2">Cookies</h4>
            <p className="text-600 text-justify pb-2">
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Textdateien und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert, bis Sie diese selbst löschen oder eine
              automatische Löschung durch Ihren Webbrowser erfolgt.
            </p>

            <p className="text-600 text-justify pb-2">
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem
              Endgerät gespeichert werden, wenn Sie unsere Seite betreten
              (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
              Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
              Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </p>

            <p className="text-600 text-justify pb-2">
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Websitefunktionen ohne diese
              nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies dienen dazu, das
              Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
            </p>

            <p className="text-600 text-justify pb-2">
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs (notwendige Cookies) oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
              (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
              Optimierung der Website (z.B. Cookies zur Messung des
              Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
              Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Speicherung von Cookies zur
              technisch fehlerfreien und optimierten Bereitstellung seiner
              Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
              abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
              lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
            </p>

            <p className="text-600 text-justify pb-2">
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>

            <p className="text-600 text-justify pb-2">
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken
              eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
              Datenschutzerklärung gesondert informieren und ggf. eine
              Einwilligung abfragen.
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">Server-Log-Dateien</h4>
            <p className="text-600 text-justify pb-2">
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log- Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </p>
            <ul className="text-600 text-justify pb-2">
              <li>Browsertyp und Browserversion</li>
              <li>Verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>

            <p className="text-600 text-justify pb-2">
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </p>

            <p className="text-600 text-justify pb-2">
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">Kontaktformular</h4>
            <p className="text-600 text-justify pb-2">
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>

            <p className="text-600 text-justify pb-2">
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </p>

            <p className="text-600 text-justify pb-2">
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </div>

          <div>
            <h3 className=" text-header pb-1 pt-1 ">
              4. Analyse-Tools und Werbung
            </h3>
            <h4 className="text-600 pb-2">Matomo (ehemals Piwik)</h4>
            <p className="text-600 text-justify pb-2">
              Diese Website benutzt den Open Source Webanalysedienst Matomo.
              Matomo verwendet Technologien, die die seitenübergreifende
              Wiedererkennung des Nutzers zur Analyse des Nutzerverhaltens
              ermöglichen (z.B. Cookies oder Device-Fingerprinting). Die durch
              Matomo erfassten Informationen über die Benutzung dieser Website
              werden auf unserem Server gespeichert. Die IP-Adresse wird vor der
              Speicherung anonymisiert.
            </p>

            <p className="text-600 text-justify pb-2">
              Mit Hilfe von Matomo sind wir in der Lage Daten über die Nutzung
              unserer Website durch die Websitebesucher zu erfassen und zu
              analysieren. Hierdurch können wir u. a. herausfinden, wann welche
              Seitenaufrufe getätigt wurden und aus welcher Region sie kommen.
              Außerdem erfassen wir verschiedene Logdateien (z.B. IP-Adresse,
              Referrer, verwendete Browser und Betriebssysteme) und können
              messen, ob unsere Websitebesucher bestimmte Aktionen durchführen
              (z.B. Klicks, Käufe u. Ä.).
            </p>

            <p className="text-600 text-justify pb-2">
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der anonymisierten Analyse des Nutzerverhaltens, um
              sowohl sein Webangebot als auch seine Werbung zu optimieren.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>

            <h4 className="text-600 pb-2">Hosting</h4>
            <p className="text-600 text-justify pb-2">
              Wir hosten Matomo ausschließlich auf unseren eigenen Servern,
              sodass alle Analysedaten bei uns verbleiben und nicht
              weitergegeben werden.
            </p>
          </div>

          <div>
            <h3 className=" text-header pb-1 pt-1 ">5. Plugins und Tools</h3>
            <h4 className="text-600 pb-2">YouTube</h4>
            <p className="text-600 text-justify pb-2">
              Diese Website bindet Videos der Website YouTube ein. Betreiber der
              Website ist die Google Ireland Limited („Google“), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </p>

            <p className="text-600 text-justify pb-2">
              Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
              eingebunden ist, wird eine Verbindung zu den Servern von YouTube
              hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
              unserer Seiten Sie besucht haben.
            </p>

            <p className="text-600 text-justify pb-2">
              Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endgerät
              speichern oder vergleichbare Technologien zur Wiedererkennung
              verwenden (z.B. Device-Fingerprinting). Auf diese Weise kann
              YouTube Informationen über Besucher dieser Website erhalten. Diese
              Informationen werden u. a. verwendet, um Videostatistiken zu
              erfassen, die Anwenderfreundlichkeit zu verbessern und
              Betrugsversuchen vorzubeugen.
            </p>

            <p className="text-600 text-justify pb-2">
              Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
              YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
              zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
              YouTube-Account ausloggen.
            </p>

            <p className="text-600 text-justify pb-2">
              Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>

            <p className="text-600 text-justify pb-2">
              Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von YouTube unter:{' '}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
          </div>

          <div>
            <h4 className="text-600 pb-2">Google Maps</h4>
            <p className="text-600 text-justify pb-2">
              Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>

            <p className="text-600 text-justify pb-2">
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
              IP-Adresse zu speichern. Diese Informationen werden in der Regel
              an einen Server von Google in den USA übertragen und dort
              gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
              diese Datenübertragung.
            </p>

            <p className="text-600 text-justify pb-2">
              Die Nutzung von Google Maps erfolgt im Interesse einer
              ansprechenden Darstellung unserer Online-Angebote und an einer
              leichten Auffindbarkeit der von uns auf der Website angegebenen
              Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>

            <p className="text-600 text-justify pb-2">
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/gdprcontrollerterms/
              </a>{' '}
              und
              <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
              </a>
              .
            </p>

            <p className="text-600 text-justify pb-2">
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Google:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Datenschutz;
