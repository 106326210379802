import React, { useEffect } from 'react';
import Hero from './Hero';
import Processes from './Processes';
import Services from './Services';
import Testimonial from './Testimonial';
import { useLocation } from 'react-router-dom';
import KontactForm from './KontactForm';
import { Helmet } from 'react-helmet-async';

const Landing = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>ScanBox product</title>
        <meta
          name="description"
          content="Ein benutzerfreundliches Tool unterstützt IT-Administratoren bei der effizienten Sicherheitsanalyse"
        />
      </Helmet>
      <div id="Startseite">
        <Hero />
      </div>

      <div id="Features">
        <Processes />
      </div>
      <div id="dienstleistungen">
        <Services />
      </div>
      <div id="referenzen">
        <Testimonial />
      </div>

      <div id="KontactForm">
        <KontactForm />
      </div>

      {/*<div id="partners" ref={partnersRef}><Partners /></div>*/}

      {/* <div id="Cta">
        <Cta />
      </div>*/}
    </>
  );
};

export default Landing;
