import illustration3 from 'assets/img/icons/Data extraction-amico.png';
import illustration2 from 'assets/img/icons/Safe-pana.png';
import illustration1 from 'assets/img/icons/Security On-rafiki.png';
import illustration6 from 'assets/img/icons/Site Stats-rafiki.svg';

export default [
  {
    title: 'Log und Traffic Datensammlung',
    description:
      'Erfasst und analysiert sämtliche Log und Netzwerkverkehrsdaten, um ungewöhnliche Aktivitäten frühzeitig zu erkennen. Durch die sofortige Erkennung von Anomalien unterstützt ScanBox die schnelle Reaktion auf potenzielle Sicherheitsbedrohungen und hilft, Ihr Netzwerk sicher zu halten.',
    image: illustration6,
    attribution: {
      link: 'https://storyset.com/business',
      text: 'Business illustrations by Storyset',
    },
  },

  {
    title: 'Cyber Threat Intelligence Korrelation',
    description:
      'Nutzt aktuelle Bedrohungsdaten, um verdächtige Muster zu identifizieren und Bedrohungen präzise zu korrelieren. Dadurch ermöglicht ScanBox eine proaktive Verteidigung, indem es Bedrohungen schneller erkennt und entsprechende Maßnahmen einleitet.',
    image: illustration2,
    inverse: true,
    attribution: {
      link: 'https://storyset.com/security',
      text: 'Security illustrations by Storyset',
    },
  },
  {
    title: 'Asset, User und Alert Sicherheitsanalyse',
    description:
      'Bietet detaillierte Sicherheitsanalysen für Assets, Benutzer und Alarme, um umfassende Einblicke in das Sicherheitsgeschehen zu ermöglichen. Dadurch können Sicherheitslücken schneller erkannt und behoben werden, was die allgemeine Sicherheit erheblich verbessert.',
    image: illustration3,
    attribution: {
      link: 'https://storyset.com/business',
      text: 'Business illustrations by Storyset',
    },
  },
  {
    title: 'Regelmäßige Sicherheitsberichte',
    description:
      'Zugriff auf Sicherheitsberichte, die übersichtlich und verständlich die aktuelle Bedrohungslage darstellen. Diese Berichte helfen dabei, fundierte Entscheidungen zu treffen und die Sicherheitsstrategie kontinuierlich zu verbessern.',
    image: illustration1,
    attribution: {
      link: 'https://storyset.com/technology',
      text: 'Technology illustrations by Storyset',
    },
  },
];
